import { booleanAttribute, Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-image-view, image-view',
  template:`
    <div class="image-container">
      <div class="image img-center"
           [class.radius]="rounded"
           [style.width]="width"
           [style.height]="height"
           [style.borderRadius]="borderRadius+'px'"
           [style.backgroundImage]="'url('+image+')'"
           [ngStyle]="{backgroundColor : image ? null : bgColor }"
      >
        <div class="centered align-center" *ngIf="placeholderImage && !image"
             [style.width]="placeholderWidth"
        >
          <img [src]="'./assets/svg/'+placeholderImage+'.svg'" alt="image"
               [style.height]="placeholderHeight"
               [style.width]="placeholderWidth ? placeholderWidth : placeholderHeight"
          >
        </div>
      </div>
    </div>
  `,
  styles: [
      `
      .image {
        position: relative;
      }
      .radius {
        border-radius: 100% !important;
      }
    `
  ],
  imports: [
    CommonModule
  ]
})
export class ImageViewComponent implements OnInit{

  @Input()
  public image: string | null = '';

  @Input()
  public width: string = '';

  @Input()
  public height: string = '';

  @Input({ transform: booleanAttribute })
  public rounded: boolean = false;

  @Input()
  public borderRadius: number = 8;

  @Input()
  public placeholderImage: string = 'user-circle';

  @Input()
  public placeholderWidth: string = '';

  @Input()
  public placeholderHeight: string = 'auto';

  @Input()
  public type!: 'user';

  @Input()
  public noPlaceholder!: boolean;

  @Input()
  public bgColor: string | null = '#D9D9D9';

  ngOnInit(): void {
    this.roundedImage();
  }

  public roundedImage(): void {
    if(this.rounded && this.type === 'user') {
      this.height = this.width;
      this.placeholderWidth = this.width;
      this.placeholderHeight = this.width;
      this.placeholderImage = 'user-circle';
    }
  }

}
